import React, { useState } from "react";
import { Button, ButtonGroup, Tooltip } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import TextsmsIcon from "@mui/icons-material/Textsms";

function ContactControls({ number, closeModal }) {
  const [toggleHoverBg, setToggleHoverBg] = useState("white");
  const [toggleHoverText, setToggleHoverText] = useState("#17a34a");
  const [toggleHoverBgSms, setToggleHoverBgSms] = useState("white");
  const [toggleHoverTextSms, setToggleHoverTextSms] = useState("#1976d2");

  const dialClient = (number) => {
    const widgetToggle = document.querySelector(".Adapter_toggle");
    const widgetContainer = document.querySelector(".Adapter_root");
    if (widgetContainer.classList.contains("Adapter_minimized")) {
      widgetToggle.click();
    }
    if (closeModal) {
      closeModal();
    }

    document
      .querySelector("#rc-widget-adapter-frame")
      .contentWindow.postMessage(
        {
          type: "rc-adapter-new-call",
          phoneNumber: number,
          toDial: false,
        },
        "*"
      );
  };

  const messageClient = (number) => {
    const widgetToggle = document.querySelector(".Adapter_toggle");
    const widgetContainer = document.querySelector(".Adapter_root");
    if (widgetContainer.classList.contains("Adapter_minimized")) {
      widgetToggle.click();
    }

    if (closeModal) {
      closeModal();
    }

    document
      .querySelector("#rc-widget-adapter-frame")
      .contentWindow.postMessage(
        {
          type: "rc-adapter-new-sms",
          phoneNumber: number,
          conversation: true, // will go to conversation page if conversation existed
        },
        "*"
      );
  };

  const hoverActive = (baseColor) => {
    setToggleHoverBg(baseColor);
    setToggleHoverText("white");
  };

  const hoverOff = (baseColor) => {
    setToggleHoverBg("white");
    setToggleHoverText(baseColor);
  };

  const hoverActiveSMS = (baseColor) => {
    setToggleHoverBgSms(baseColor);
    setToggleHoverTextSms("white");
  };

  const hoverOffSMS = (baseColor) => {
    setToggleHoverBgSms("white");
    setToggleHoverTextSms(baseColor);
  };

  return (
    <ButtonGroup className="mx-auto">
      <Tooltip title="Call using Ring Central">
        <Button
          onClick={() => dialClient(number)}
          style={{
            padding: "5px 1rem",
            display: "flex",
            justifyContent: "center",
            border: `1px solid ${toggleHoverText}`,
            width: "fit-content",
            margin: "0 auto",
            backgroundColor: toggleHoverBg,
            color: toggleHoverText,
          }}
          onMouseEnter={() => hoverActive("#17a34a")}
          onMouseLeave={() => hoverOff("#17a34a")}
        >
          <PhoneIcon className="text-[16px]" />
        </Button>
      </Tooltip>
      <Tooltip title="Text using Ring Central">
        <Button
          onClick={() => messageClient(number)}
          style={{
            padding: "5px 1rem",
            display: "flex",
            justifyContent: "center",
            border: `1px solid ${toggleHoverTextSms}`,
            width: "fit-content",
            margin: "0 auto",
            backgroundColor: toggleHoverBgSms,
            color: toggleHoverTextSms,
          }}
          onMouseEnter={() => hoverActiveSMS("#1976d2")}
          onMouseLeave={() => hoverOffSMS("#1976d2")}
        >
          <TextsmsIcon className="text-[16px]" />
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
}

export default ContactControls;
